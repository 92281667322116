<template>
  <div v-if="editModeEnabled && operationType === operationTypes.BUYING" class="col-12">
    <app-title label="TED CLIENTE" />
    <div class="operation">
      <div class="row">
        <div class="col-6">
          <label>Nome:</label>
          <p>{{ tedClientDetail?.company || emptyPlaceholder }}</p>
        </div>
        <div class="col-6">
          <label>Banco:</label>
          <p>{{ tedClientDetail?.name || emptyPlaceholder }}</p>
        </div>

        <div class="col-6">
          <label>Agência:</label>
          <p>{{ tedClientDetail?.agency || emptyPlaceholder }}</p>
        </div>
        <div class="col-6">
          <label>Conta:</label>
          <p>{{ tedClientDetail?.account || emptyPlaceholder }}</p>
        </div>

        <div class="col-12">
          <label>PIX:</label>
          <p>{{ tedClientDetail?.pix || emptyPlaceholder }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appTitle from '@/components/shared/title.vue'

export default {
  name: 'TransferenciaTedCliente',

  components: {
    appTitle
  },

  props: {
    editModeEnabled: {
      type: Boolean,
      default: false
    },
    operationType: {
      type: String,
      required: true
    },
    operationTypes: {
      type: Object,
      required: true
    },
    tedClientDetail: {
      type: Object,
      default: () => ({})
    },
    emptyPlaceholder: {
      type: String,
      default: '-'
    }
  }
}
</script>
