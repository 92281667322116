<script>
import { parseISO, format } from 'date-fns'
import { useForm } from 'vee-validate'
import { onMounted, ref, watch, toRefs, provide, reactive } from 'vue'
import { useMeta } from 'vue-meta'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import formButton from '@/components/form/button.vue'
import formDate from '@/components/form/date.vue'
import formMoney from '@/components/form/money.vue'
import formSelect from '@/components/form/select.vue'
import formSwitch from '@/components/form/switch.vue'
import { moneyFormat } from '@/composables/utils/moneyConfig'
import { operationTypes, liquidationTypeOptions } from '@/composables/utils/useGlobalConfig'
import {
  fetchMarketStatus,
  fetchOperationTaxes,
  createPosOperationHomeBroker,
  createOperationTaxesHomeBroker,
  fetchPTAX
} from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { fetchExchangeRate } from '@/services/ms'
import { useWss } from '@/stores/wss.js'

export default {
  name: 'formModalPromoCode',

  components: {
    formSwitch,
    formMoney,
    formSelect,
    formDate,
    formButton
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    initialFormData: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props, { emit }) {
    useMeta({ title: props.title })

    const contentActive = ref(false)
    const loading = ref(false)

    const { getExchangeRate, isMarketClosed } = toRefs(useWss())
    const isStatusMarketClosed = ref(isMarketClosed.value)

    const getOperation = reactive({ ...props.initialFormData })

    const currencyTypeValue = ref(getOperation.currencyType)
    const operationValue = ref(getOperation.principalAmount)
    const liquidationTypeValue = ref(getOperation.liquidationType || 'D0')
    const createdAt = parseISO(getOperation.createdAt)
    const createAtDateValue = ref(format(createdAt, 'dd/MM/yyyy HH:mm'))
    const operationType = ref(getOperation.type)

    const isPositive = ref(null)
    const vet = ref('')
    const iof = ref(getOperation.iof)
    const ir = ref(getOperation.ir)

    const ptax = ref('')

    const spread = ref('')
    const liquidation = ref('')
    const spreadType = ref('')
    const clientTax = ref('')
    const validityValue = ref('')

    const commercialQuote = ref('')
    const commercialQuoteUSD = ref('')
    const hasTargetRate = ref(false)

    const nowFinalQuote = ref('')
    const convertedAmountRS = ref('')
    const clientTaxRS = ref(0)
    const finalPriceRS = ref('')
    const hasEditingClientTaxRS = ref(false)

    watch(
      getExchangeRate,
      (newVal) => {
        if (newVal != null && newVal[getOperation.currencyType] != null) {
          formatCurrencyType(newVal[getOperation.currencyType])
        }
      },
      { deep: true }
    )

    const loadMarketStatus = async () => {
      try {
        const response = await fetchMarketStatus()
        isStatusMarketClosed.value = response.data.payload
        useWss().market = response.data.payload
      } catch (e) {
        handleGlobalError(e, 'fetchMarketStatus')
      }
    }

    watch(isMarketClosed, (newVal) => {
      isStatusMarketClosed.value = newVal
    })

    const loadCurrencyInfo = async () => {
      try {
        const fetchCurrencyData = async (currencyType) => {
          const response = await fetchExchangeRate(currencyType)
          return response.data.payload
        }

        let payload = await fetchCurrencyData(getOperation.currencyType)
        let payloadUSD = null

        if (getOperation.currencyType !== 'USD') {
          payloadUSD = await fetchCurrencyData('USD')
        }

        commercialQuote.value = Number(
          operationType.value === 'BUYING' ? payload.buyPrice : payload.sellPrice
        )

        if (payloadUSD) {
          commercialQuoteUSD.value = Number(
            operationType.value === 'BUYING' ? payloadUSD.buyPrice : payloadUSD.sellPrice
          )
        }

        calculateOperation()
      } catch (e) {
        handleGlobalError(e, 'loadCurrencyInfo')
      }
    }

    watch(liquidationTypeValue, () => {
      fetchApiOperationTaxes()
    })

    const enableClientTaxEdit = () => {
      if (!hasEditingClientTaxRS.value) hasEditingClientTaxRS.value = true
    }

    const fetchApiOperationTaxes = async () => {
      try {
        const response = await fetchOperationTaxes(
          getOperation.id,
          getOperation.clientId,
          liquidationTypeValue.value
        )
        spread.value = response.data.payload.spread
        liquidation.value = response.data.payload.liquidation
        spreadType.value = response.data.payload.spreadType
        clientTax.value = response.data.payload.clientTax

        calculateOperation()
      } catch (e) {
        handleGlobalError(e, 'fetchOperationTaxes')
      }
    }

    const operationSign = () => {
      return operationType.value === 'BUYING' ? 1 : -1
    }

    const calcFinalQuote = (quote = null) => {
      if (hasTargetRate.value) {
        return nowFinalQuote.value
      }
      const commercial = quote || Number(commercialQuote.value)
      const operationSpread =
        spreadType.value === 'PERCENTAGE'
          ? commercial * (Number(spread.value) / 100.0)
          : Number(spread.value)
      return Number(commercial + operationSign() * (operationSpread + Number(liquidation.value)))
    }

    const calcClientTaxRS = () => {
      if (hasEditingClientTaxRS.value) {
        return clientTaxRS.value
      }
      return clientTax.value * calcFinalQuote(commercialQuoteUSD.value)
    }

    const calcConvertedAmountRS = () => {
      const finalQuote = Number(calcFinalQuote())
      return Number(finalQuote.toFixed(4)) * operationValue.value
    }

    const calcFinalPriceRS = () => {
      return calcConvertedAmountRS() + operationSign() * (calcIOF() + calcClientTaxRS())
    }

    const calcVET = () => {
      return calcFinalPriceRS() / operationValue.value
    }

    const calcIOF = () => {
      let valueIOF = 0
      valueIOF = calcConvertedAmountRS() * iof.value
      return Number(valueIOF.toFixed(2))
    }

    const calcIR = () => {
      let valueIR = 0
      const quote = calcFinalQuote() <= ptax.value ? ptax.value : calcFinalQuote()
      valueIR = operationValue.value * quote * ir.value
      return valueIR
    }

    const fetchPtaxClose = async () => {
      try {
        const response = await fetchPTAX(getOperation.currencyType)
        ptax.value = response.data.payload.ptax || 0
      } catch (e) {
        handleGlobalError(e, 'fetchPtaxClose')
      }
    }

    const formatCurrencyType = (value) => {
      if (hasTargetRate.value) return
      const splits = value.split('|')
      const getQuote = () => Number(splits[operationType.value === 'BUYING' ? 6 : 7])
      const updateQuote = (currencyType, newQuote, quoteVar) => {
        if (splits.includes(currencyType)) {
          const previousQuote = quoteVar.value
          quoteVar.value = newQuote

          if (previousQuote !== null) {
            isPositive.value = newQuote >= previousQuote
          }
        }
      }

      const newQuote = getQuote()
      updateQuote(getOperation.currencyType, newQuote, commercialQuote)

      if (getOperation.currencyType !== 'USD') {
        const newUSDQuote = getQuote()
        updateQuote('USD', newUSDQuote, commercialQuoteUSD)
      }

      calculateOperation()

      setTimeout(() => {
        isPositive.value = null
      }, 1000)
    }

    const calculateOperation = () => {
      convertedAmountRS.value = calcConvertedAmountRS()
      clientTaxRS.value = calcClientTaxRS()
      iof.value = calcIOF()
      ir.value = calcIR()
      vet.value = calcVET()
      finalPriceRS.value = calcFinalPriceRS()

      if (!hasEditingClientTaxRS.value) {
        clientTaxRS.value = calcClientTaxRS()
      }

      if (!hasTargetRate.value) {
        nowFinalQuote.value = calcFinalQuote()
      }
    }

    watch([spread, clientTaxRS, nowFinalQuote], () => {
      calculateOperation()
    })

    const loadTradingViewScript = () => {
      const widgetContainer = document.querySelector('.tradingview-widget-container__widget')

      if (widgetContainer) {
        widgetContainer.innerHTML = ''
      }
      const script = document.createElement('script')
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js'
      script.async = true
      script.innerHTML = `
        {
          "autosize": true,
          "symbol": "FX_IDC:${getOperation.currencyType}BRL",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "br",
          "backgroundColor": "#222529",
          "gridColor": "rgba(0, 255, 255, 0.06)",
          "hide_top_toolbar": true,
          "details": true,
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }

      `
      document.querySelector('.tradingview-widget-container__widget').appendChild(script)
    }

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        spreadPercentage: yup.string().test((value) => value !== '0.0000'),
        validity: yup.string().test(function (value) {
          if (hasTargetRate.value) {
            return !!value
          }
          return true
        })
      })
    })

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    const onSubmitAction = () => {
      alreadySubmittedOnce.value = true
      return onSubmit()
    }

    const onSubmit = handleSubmit(async () => {
      loading.value = true
      const commonBody = {
        operationId: getOperation.id,
        liquidationType: liquidationTypeValue.value,
        spread: Number(spread.value),
        clientTaxRs: clientTaxRS.value
      }

      let body
      if (hasTargetRate.value) {
        body = {
          ...commonBody,
          principalAmount: getOperation.principalAmount,
          finalQuote: Number(nowFinalQuote.value),
          operationType: operationType.value,
          currencyType: getOperation.currencyType,
          spreadType: spreadType.value,
          expiresAt: validityValue.value
        }
        try {
          await createOperationTaxesHomeBroker(body)

          toastSuccess('Operação finalizada com sucesso!')
          closeOperation()
        } catch (e) {
          handleGlobalError(e, 'createPosOperationHomeBroker')
        } finally {
          loading.value = true
        }
      } else {
        body = {
          ...commonBody,
          commercialQuote: Number(commercialQuote.value),
          commercialQuoteUSD: commercialQuoteUSD.value
            ? Number(commercialQuoteUSD.value)
            : undefined
        }
        try {
          await createPosOperationHomeBroker(body)
          toast('Operação taxa alvo criada com sucesso!', {
            theme: 'auto',
            type: 'success',
            dangerouslyHTMLString: true
          })

          toastSuccess('Operação taxa alvo criada com sucesso!')
          closeOperation()
        } catch (e) {
          handleGlobalError(e, 'createOperationTaxesHomeBroker')
        } finally {
          loading.value = false
        }
      }
    })

    const toastSuccess = (message) => {
      toast(message, {
        theme: 'auto',
        type: 'success',
        dangerouslyHTMLString: true
      })
    }

    const closeOperation = () => {
      closeModal()
      emit('preClose', true)
    }

    const closeModal = () => {
      const widgetContainer = document.querySelector('.tradingview-widget-container__widget')
      if (widgetContainer) {
        widgetContainer.innerHTML = ''
      }

      contentActive.value = false
      setTimeout(() => {
        emit('onCloseHomeBroker', false)
      }, 300)
    }

    const openModal = () => {
      fetchApiOperationTaxes()
      loadMarketStatus()
      fetchPtaxClose()

      setTimeout(() => {
        contentActive.value = true
      }, 100)
    }

    onMounted(() => {
      loadCurrencyInfo()

      loadTradingViewScript()
      openModal()
    })

    const formatNumber = (value, locale, decimal) => {
      const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: decimal || 2,
        maximumFractionDigits: decimal || 2
      })

      return formatter.format(value)
    }

    const formatOperationType = (value) => {
      return value ? (value == operationTypes.SELLING ? 'VENDA' : 'COMPRA') : ''
    }

    return {
      operationType,
      contentActive,
      currencyTypeValue,
      createAtDateValue,
      operationValue,
      liquidationTypeValue,

      liquidationTypeOptions,

      onSubmitAction,
      closeModal,

      formatNumber,
      moneyFormat,
      isPositive,
      formatOperationType,
      operationTypes,
      validityValue,

      hasTargetRate,
      spread,
      liquidation,
      vet,
      nowFinalQuote,

      clientTaxRS,
      hasEditingClientTaxRS,
      enableClientTaxEdit,

      loading
    }
  }
}
</script>

<template>
  <div class="box-content d-flex justify-content-end">
    <div :class="['back', { active: contentActive }]" @click="closeModal()"></div>
    <div :class="['content-home-broker', { active: contentActive }]">
      <div class="row">
        <h4 class="col-8">{{ title }}</h4>
        <div class="col-4 text-end">
          <h5>
            <span :class="operationType === operationTypes.SELLING ? 'color-sell' : 'color-buy'">
              <b>{{ formatOperationType(operationType) }}</b>
            </span>
            {{ currencyTypeValue }} {{ formatNumber(operationValue, 'pt-BR') }}
          </h5>
          <p>{{ createAtDateValue }}</p>
        </div>
      </div>

      <div class="row">
        <div class="tradingview-widget-container">
          <div class="tradingview-widget-container__widget"></div>
        </div>
      </div>

      <div class="row ps-2 pe-2 information-bar">
        <div class="d-flex justify-content-between">
          <p>
            Spread: <b>% {{ formatNumber(spread, 'pt-BR', 4) }}</b>
          </p>
          <p>
            Custo liquidação: <b>R$ {{ formatNumber(liquidation, 'pt-BR', 4) }}</b>
          </p>
          <p>IOF: <b>R$ 0,00</b></p>
          <p>IR: <b>R$ 0,00</b></p>
          <p>
            Taxa VET: <b>R$ {{ formatNumber(vet, 'pt-BR', 4) }}</b>
          </p>
        </div>
      </div>

      <div class="row g-2">
        <form-switch
          className="col-4 form-input"
          label="Modo taxa alvo"
          description="Ativar/Desativar taxa alvo"
          v-model="hasTargetRate"
          id="hasTargetRate"
          name="hasTargetRate"
        />
        <form-money
          className="col-4 form-input"
          v-model="spread"
          label="Spread"
          id="spreadPercentage"
          name="spreadPercentage"
          :config="moneyFormat('%', ',', '.', 4)"
        />
        <form-money
          className="col-4 form-input"
          v-model="clientTaxRS"
          label="Tarifa total"
          id="clientTaxRS"
          name="clientTaxRS"
          :config="moneyFormat('R$ ', '.', ',', 2)"
          @click="enableClientTaxEdit"
        />
      </div>

      <div class="row g-2">
        <form-money
          className="col-4 form-input"
          v-model="nowFinalQuote"
          :label="`Preço de (${formatOperationType(operationType)})`"
          id="nowFinalQuote"
          name="nowFinalQuote"
          :config="moneyFormat(`${currencyTypeValue} `, '.', ',', 4)"
          :isDisabled="!hasTargetRate"
        />
        <form-select
          v-if="operationType === operationTypes.BUYING"
          class="col-4 form-input"
          label="Fechamento em:"
          v-model="liquidationTypeValue"
          :items="liquidationTypeOptions"
          id="liquidationType"
          name="liquidationType"
        />
        <form-date
          v-if="hasTargetRate"
          class="col-4 form-input"
          label="Validade"
          v-model="validityValue"
          id="validity"
          name="validity"
          icon="bi bi-calendar-week"
        />
      </div>
      <div class="row g-2">
        <div class="col closing-information">
          <label>Quantidade</label>
          <p>{{ currencyTypeValue }} {{ formatNumber(operationValue, 'pt-BR') }}</p>
        </div>
        <div class="col closing-information">
          <label>Valor total estimado</label>
          <p
            :class="{
              positive: isPositive === true,
              negative: isPositive === false
            }"
          >
            R$ {{ formatNumber(operationValue * vet, 'pt-BR') }}
          </p>
        </div>
        <form-button
          :class="operationType === operationTypes.SELLING ? 'submit-sell' : 'submit-buy'"
          class="col-4 form-input"
          :title="hasTargetRate ? 'Criar taxa alvo' : 'Enviar ordem a mercado'"
          :loading="loading"
          :isDisabled="loading"
          @clicked="onSubmitAction"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.box-content {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  padding: 0;

  .back {
    background-color: rgba(0, 0, 0, 0);
    transition: background 0.36s cubic-bezier(0.32, 0.08, 0.55, 1);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s;

    &.active {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .content-home-broker {
    background-color: var(--body-background);
    transition: right 0.3s;
    overflow: auto;
    height: 100%;
    width: 66%;
    z-index: 1;
    position: fixed;
    top: 0;
    right: -66%;
    padding: 44px 38px;

    .color-sell {
      color: #71cc98;
    }

    .color-buy {
      color: #6195e1;
    }

    .form-input {
      &.submit-buy {
        button {
          background-color: #6195e1;
          border-color: #6195e1;
          color: #ffffff;
        }
      }
      &.submit-sell {
        button {
          background-color: #71cc98;
          border-color: #71cc98;
          color: #ffffff;
        }
      }
    }

    &.active {
      right: 0;
    }

    h4 {
      font-size: 36px;
      font-weight: 500;
    }

    .tradingview-widget-container {
      height: 400px;

      .tradingview-widget-container__widget {
        height: 400px;
      }
    }

    .information-bar {
      > div {
        background-color: #18191e;
        border-radius: 9px;
        padding: 10px 14px;
        margin-top: 30px;
        margin-bottom: 30px;

        p {
          white-space: nowrap;
          margin-bottom: 0;
        }

        b {
          color: #fff;
        }
      }
    }

    .closing-information {
      padding-top: 24px;

      label {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 7px;
        color: var(--label-color);
        width: 100%;
        display: block;
        line-height: 1;
      }

      p {
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: #ffffff;
        margin-bottom: 0;
        transition: color 0.1s ease-in-out;

        &.positive {
          color: #049981;
        }

        &.negative {
          color: #f23645;
        }
      }
    }
  }
}
</style>
