<script>
import { ref } from 'vue'
import { useMeta } from 'vue-meta'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import formModal from '@/components/shared/formModal.vue'
import appModal from '@/components/shared/modal.vue'
import appTableSection from '@/components/shared/tableSection.vue'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { usePaginatedResult } from '@/composables/utils/usePaginatedResult'
import { fetchHolidays, createHoliday, deleteHoliday } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appHolidays',

  components: {
    appTableSection,
    formModal,
    appModal
  },

  setup() {
    useMeta({ title: 'Feriados' })

    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const { setNoScroll } = useNoScroll()

    const tableHeaders = [
      { key: 'name', label: 'Nome', type: 'text' },
      { key: 'date', label: 'Data', type: 'date', format: 'dd/MM/yyyy' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          { action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' },
          { action: 'delete', label: 'Deletar', class: 'delete', emitEvent: 'delete' }
        ]
      }
    ]

    const fetchFunction = (config) => fetchHolidays(config)

    const resetAndUpdateResult = (shouldReset) => {
      if (shouldReset) resetResult()

      const config = {
        page: currentPage.value
      }
      fetchResult(config)
    }

    const {
      resultItems: holidays,
      loading,
      isLastPage,
      fetchResult,
      resetResult,
      currentPage
    } = usePaginatedResult(fetchFunction, resetAndUpdateResult)

    const title = ref('')
    const initialFormData = ref({})
    const modalEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)

    const formSchema = [
      {
        component: 'formInput',
        name: 'name',
        label: 'Nome',
        class: 'col-12 form-input',
        value: 'name',
        rules: yup.string().required()
      },
      {
        component: 'formDate',
        name: 'date',
        label: 'Dia',
        class: 'col-12 form-input',
        value: 'date',
        rules: yup.string().required(),
        config: {
          shouldIgnoreTimezone: true
        }
      }
    ]

    const newHoliday = () => {
      title.value = 'Adicionar novo feriado'
      modalEnabled.value = true
    }

    const handleEdit = (item) => {
      title.value = 'Editar feriado'
      initialFormData.value = { ...item }

      editModeEnabled.value = true
      modalEnabled.value = true
    }

    const handleFormCreation = async (formData) => {
      if (!editModeEnabled.value) {
        await handleSubmitHoliday(formData)
      } else {
        await handleUpdateHoliday(formData)
      }
    }

    const handleSubmitHoliday = async (formData) => {
      loadingButton.value = true
      try {
        await createHoliday(formData)
        modalEnabled.value = false
        resetAndUpdateResult()
        toast('Feriado cadastrado', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdateHoliday = async (updatedForm) => {
      loadingButton.value = true
      try {
        await createHoliday(updatedForm)
        resetAndUpdateResult()
        modalEnabled.value = false
        toast('Feriado editado', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        editModeEnabled.value = false
        setNoScroll(false)
      }
    }

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-holiday',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Deseja mesmo apagar este feriado, ação não poderá ser revertida.',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.id
      }
      modalFormIsShown.value = true
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-holiday') {
        handleDeleteHoliday(item)
      }
    }

    const handleDeleteHoliday = async (item) => {
      try {
        loadingModal.value = true
        await deleteHoliday(item.id)
        resetAndUpdateResult(true)
        toast('Feriado removido', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
        setNoScroll(false)
      }
    }

    const loadingModal = ref(false)

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalEnabled.value = false
    }

    return {
      tableHeaders,
      holidays,
      loading,
      isLastPage,

      newHoliday,

      modalFormIsShown,
      modalEnabled,
      modalConfig,
      title,
      formSchema,

      handleFormClose,
      handleEdit,
      initialFormData,
      loadingButton,

      handleFormCreation,
      handleDelete,

      handleConfirm,
      loadingModal
    }
  }
}
</script>

<template>
  <div>
    <form-modal
      v-if="modalEnabled"
      @onClose="handleFormClose"
      :title="title"
      :formSchema="formSchema"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <div class="row title">
      <h2 class="col">Feriados</h2>
      <div class="col-12 col-md-3 form-input">
        <button class="secondary" @click="newHoliday()">
          <i class="bi bi-node-plus-fill"></i>
          Novo Feriado
        </button>
      </div>
    </div>

    <app-table-section
      :items="holidays"
      :headers="tableHeaders"
      :loading="loading"
      :isLastPage="isLastPage && !loading"
      :eventHandlers="{
        edit: handleEdit,
        delete: handleDelete
      }"
    />
  </div>
</template>
