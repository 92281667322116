<script>
import { parseISO, format, isValid } from 'date-fns'
import { useField } from 'vee-validate'
import { ref, computed, inject, watch } from 'vue'

export default {
  name: 'appDate',
  props: {
    className: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Date],
      default: ''
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => ({ shouldIgnoreTimezone: false })
    }
  },
  setup(props, { emit }) {
    const { value, errorMessage, handleChange } = useField(() => props.name, props.rules, {
      syncVModel: true,
      validateOnValueUpdate: false
    })

    const alreadySubmittedOnce = inject('alreadySubmittedOnce', ref(false))

    const handleChangeValidation = (selectedDate) => {
      if (selectedDate instanceof Date && !isNaN(selectedDate)) {
        const formattedDate = format(selectedDate, 'yyyy-MM-dd')
        emit('update:modelValue', formattedDate)
        handleChange(formattedDate, alreadySubmittedOnce.value)
      }
    }

    const forceLocalDate = (dateString) => {
      dateString = dateString.includes('T')
        ? dateString.split('T')[0]
        : dateString

      if (!dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return new Date(dateString)
      }

      const [year, month, day] = dateString.split('-').map(Number)
      return new Date(year, month - 1, day)
    };

    const valueInput = computed(() => {
      const placeholder = 'DD/MM/YYYY'
      if (value.value == null) return placeholder

      let parsedDate = value.value

      if (typeof parsedDate === 'string') {
        parsedDate = props.config.shouldIgnoreTimezone
          ? forceLocalDate(parsedDate)
          : new Date(parsedDate)
      }

      return isValid(parsedDate)
        ? format(parsedDate, 'dd/MM/yyyy')
        : placeholder
    })

    watch(
      () => props.modelValue,
      (newVal) => {
        if (newVal == null) return
        if (props.modelValue === newVal) return

        const formattedDate = format(new Date(newVal), 'yyyy-MM-dd')
        emit('update:modelValue', formattedDate)
      },
      { immediate: true }
    )

    return {
      value,
      valueInput,
      errorMessage,
      handleChangeValidation
    }
  }
}
</script>

<template>
  <div :class="className">
    <label :for="id">{{ label }}</label>
    <i v-if="icon" :class="icon"></i>
    <VDatePicker v-model="value" @dayclick="handleChangeValidation">
      <template #default="{ togglePopover }">
        <input
          :id="id"
          :name="name"
          :value="valueInput"
          type="button"
          :disabled="isDisabled"
          :class="{ invalid: isInvalid || !!errorMessage }"
          @click="togglePopover"
        />
      </template>
    </VDatePicker>
  </div>
</template>

<style lang="scss">
.vc-header {
  .vc-title,
  .vc-arrow {
    background-color: transparent;
  }
}
</style>
