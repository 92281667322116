<script>
import { onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'
import * as yup from 'yup'

import formModal from '@/components/shared/formModal.vue'
import appModal from '@/components/shared/modal.vue'
import appTableSection from '@/components/shared/tableSection.vue'
import { configCommercialMoney } from '@/composables/utils/moneyConfig'
import { fetchAndProcessMasks, fetchVariation } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { updateMask, updateVariation } from '@/services/ms'

export default {
  name: 'appMasks',

  components: {
    appTableSection,
    formModal,
    appModal
  },

  setup() {
    useMeta({ title: 'Parâmetros' })

    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const masksAndVariation = ref([])
    const loading = ref(false)

    const tableHeaders = [
      { key: 'currency', label: 'Moeda', type: 'text' },
      {
        key: 'defaultBuySpread',
        label: 'Compra',
        type: 'currency',
        locale: 'pt-BR',
        maximumFractionDigits: 4
      },
      {
        key: 'defaultSellSpread',
        label: 'Venda',
        type: 'currency',
        locale: 'pt-BR',
        maximumFractionDigits: 4
      },
      {
        key: 'variation',
        label: 'Variação',
        type: 'currency',
        locale: 'pt-BR',
        maximumFractionDigits: 4
      },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [{ action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' }]
      }
    ]

    const fetchMasks = async () => {
      loading.value = true
      try {
        const masks = await fetchAndProcessMasks()
        const variation = await fetchVariation()
        masksAndVariation.value = transformDataForComponent(
          masks.data.payload,
          variation.data.payload
        )
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    const resetMask = () => {
      masksAndVariation.value = []
    }

    const transformDataForComponent = (masks, variation) => {
      const result = Object.keys(masks.defaultSellSpread).map((currency) => {
        const currencyVariation = variation.openChange[currency]
          ? parseFloat(variation.openChange[currency])
          : null

        return {
          currency,
          currencyType: 'BRL',
          defaultSellSpread: parseFloat(masks.defaultSellSpread[currency]),
          defaultBuySpread: parseFloat(masks.defaultBuySpread[currency]),
          variation: parseFloat(currencyVariation)
        }
      })

      return result
    }

    onMounted(() => {
      fetchMasks()
    })

    const title = ref('')
    const initialFormData = ref({})
    const modalEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)

    const formSchema = [
      {
        component: 'formMoney',
        name: 'defaultBuySpread',
        label: 'Compra',
        class: 'col-12 form-input',
        value: 'defaultBuySpread',
        config: configCommercialMoney,
        rules: yup.string().required()
      },
      {
        component: 'formMoney',
        name: 'defaultSellSpread',
        label: 'Venda',
        class: 'col-12 form-input',
        value: 'defaultSellSpread',
        config: configCommercialMoney,
        rules: yup.string().required()
      },
      {
        component: 'formMoney',
        name: 'variation',
        label: 'Variação',
        class: 'col-12 form-input',
        value: 'variation',
        config: configCommercialMoney,
        rules: yup.string().required()
      }
    ]

    const handleEdit = (item) => {
      title.value = `Editar máscara ${item.currency}`
      initialFormData.value = { ...item }

      editModeEnabled.value = true
      modalEnabled.value = true
    }

    const handleUpdateMask = async (updatedForm) => {
      loadingButton.value = true
      try {
        const bodyMask = {
          buyMask: parseFloat(updatedForm.defaultBuySpread),
          sellMask: parseFloat(updatedForm.defaultSellSpread)
        }
        await updateMask(updatedForm.currency, bodyMask)

        const bodyVariation = {
          exchange: parseFloat(updatedForm.variation)
        }
        await updateVariation(updatedForm.currency, bodyVariation)

        modalEnabled.value = false
        resetMask()
        fetchMasks()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        editModeEnabled.value = false
      }
    }

    const loadingModal = ref(false)

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalEnabled.value = false
    }

    return {
      tableHeaders,
      masksAndVariation,
      loading,

      modalFormIsShown,
      modalEnabled,
      modalConfig,
      title,
      formSchema,

      handleFormClose,
      initialFormData,
      loadingButton,

      handleUpdateMask,
      handleEdit,

      loadingModal
    }
  }
}
</script>

<template>
  <div>
    <form-modal
      v-if="modalEnabled"
      @onClose="handleFormClose"
      :title="title"
      :formSchema="formSchema"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleUpdateMask"
    />

    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <div class="row title">
      <h2 class="col">Parâmetros</h2>
    </div>

    <app-table-section
      :items="masksAndVariation"
      :headers="tableHeaders"
      :loading="loading"
      :eventHandlers="{
        edit: handleEdit
      }"
    />
  </div>
</template>
